<template>
  <component :is="layoutName" />
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import EmptyLayout from '@/components/layouts/EmptyLayout.vue';
import { mapActions, mapMutations } from 'vuex';
import { MetaTitleDescriptionKeywordsChange } from "@/helpers/commonHelpers";

export default {
  components: { MainLayout, EmptyLayout },

  computed: {
    layoutName () {
      return `${(this.$route.meta.layout || 'empty')}-layout`
    },
  },

  methods: {
    ...mapMutations(['setAppWidth', 'setLang']),
    ...mapActions(['getBaseInfo']),
  },

  updated () {
    this.setAppWidth(window.screen.width);
    MetaTitleDescriptionKeywordsChange({});
  },

  async mounted () {
    this.setAppWidth(window.screen.width)
    const lang = localStorage.getItem('lang') || 'ru'
    this.setLang(lang)
    this.$i18n.locale = lang
    await this.getBaseInfo()
  },
}
</script>
