import {Project, Projects, ProjectTypes} from '@/services/interfaces/interfaces';

export default {
  setProjects: (state: any, value: Projects) => {
    state.projects.next  = value.next
    state.projects.previous = value.previous
    state.projects.count = value.count
    state.projects.results = state.projects.results.concat(value.results)
  },
  setMainProjects: (state: any, value: Project[]) => {
    state.mainProjects = value
  },
  setProjectTypes: (state: any, value: ProjectTypes) => {
    state.projectTypes = value
  },

  resetProjects: (state: any) => {
    state.projects.next  = ''
    state.projects.previous = ''
    state.projects.count = 0
    state.projects.results = []
  }
};
