import {Video, Videos} from '@/services/interfaces/interfaces';

export default {
  setVideos: (state: any, value: Videos) => {
    state.videos.results = state.videos.results.concat(value.results)
    state.videos.next = value.next
    state.videos.previous = value.previous
    state.videos.count = value.count
  },
  setMainVideos: (state: any, value: Video[]) => {
    state.mainVideos = value
  },
  setVideoTypes: (state: any, value: Videos) => {
    state.videoTypes = value
  },
  resetVideos: (state: any) => {
    state.videos = {
      count: 0,
      next: '',
      previous: '',
      results:[]
    }
  }
};
