import {News, OneNews} from '@/services/interfaces/interfaces';

export default {
  news: {
    count: 0,
    next: '',
    previous: '',
    results:[]
  } as News|unknown,
  upgrades: [] as News|unknown,
  mainNews: [] as OneNews[]|unknown,
  tag: '' as News | unknown,
};
