import api from '@/services/api/api';
import { MetaTitleDescriptionKeywordsChange } from "@/helpers/commonHelpers";

export default {
  async getProjects({ commit, rootState }: any, params: any) {
    try {
      const res = await api.getProjects(rootState.lang, params)
      if (params.pageSize === 5) {
        commit('setMainProjects', res.data.results)
      } else {
        commit('setProjects', res.data)
      }
      return res
    } catch (error) {
      return error
    }
  },

  async getProjectTypes({ commit, rootState }: any) {
    try {
      const res = await api.getProjectsTypes(rootState.lang)
      commit('setProjectTypes', res.data.results)
      return res
    } catch (error) {
      return error
    }
  },

  async getProject({ rootState }: any, id: string) {
    try {
      let res = await api.getProject(id, rootState.lang).then(res => res.data)

      MetaTitleDescriptionKeywordsChange({
        title: res.seo_title,
        description: res.seo_description,
        keyword: res.seo_keyword
      });

      return res
    } catch (error) {
      return error
    }
  },
};
