import api from '@/services/api/api';
import { MetaTitleDescriptionKeywordsChange } from "@/helpers/commonHelpers";

export default {
  async getNews({ commit, rootState }: any, payload: any) {
    try {
      const params: any = { lang: rootState.lang }
      if (payload.page) params.page = payload.page
      if (payload.pageSize) params.page_size = payload.pageSize
      if (payload.tag) params.tag = payload.tag

      const res = await api.getNews(params)
      if (payload.pageSize === 6) {
        commit('setMainNews', res.data.results)
      } else {
        commit('setNews', res.data)
      }
      return res
    } catch (error) {
      return error
    }
  },

  async getOneNews({ rootState }: any, id: string) {
    try {
      let res = await api.getOneNews(id, rootState.lang).then(res => res.data)

      MetaTitleDescriptionKeywordsChange({
        title: res.seo_title,
        description: res.seo_description,
        keyword: res.seo_keyword
      });

      return res
    } catch (error) {
      return error
    }
  },

  async getUpgrades({ commit, rootState }: any) {
    try {
      const res = await api.getUpgrades(rootState.lang)
      commit('setUpgrades', res.data.results)
      return res
    } catch (error) {
      return error
    }
  },
};
