<template>
  <section class="footer container-margin">
    <div class="partners">
      <p>{{$t('partners')}}</p>
      <div class="partners-wrapper">
        <div
          v-for="partner in baseInfo&&baseInfo.partners"
          :key="partner.img"
          @click="openPartners(partner.url)"
          class="partners__item"
        >
            <img
              :src="`${ApiUrl}v1/images${partner.img}`"
              v-if="partner.img"
              class="item-img"
            >
          <span class="item-text">{{partner.text}}</span>
        </div>
      </div>
    </div>

    <ContactsBlock
      is-address
      class="social"
    />
  </section>
</template>

<script>
import ContactsBlock from '@/components/ContactsBlock.vue';
import {mapState} from "vuex";
import {ApiUrl} from "@/global";


export default {
  name: 'Header',
  data () {
    return {
      ApiUrl
    }
  },
  methods: {
    openPartners(url){
      window.open(url, '_blank');
    }
  },
  computed: {
    ...mapState(['baseInfo']),
  },

  components: { ContactsBlock },
};
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  border-top: 1px solid #c4c4c4;
  flex-wrap: wrap;
}

.social {
  margin-bottom: 20px;
}

.ministry-label {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  word-wrap: break-word;
  font-family: inherit;
}

.partners {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    margin-right: 23px;
    white-space: nowrap;
  }
}

.partners-wrapper {
  display: flex;
  align-items: center;
  .partners__item{
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .item-img {
    margin-right: 8px;
    margin-left: 25px;
    height: 34px;
  }
  .item-text{
    font-size: 11px;
    line-height: 14px;
  }
}

@media screen and (max-width: 1100px) {
  .footer{
    display: grid;
  }
  .social {
    margin-bottom: 0;
    margin-top: 0;
  }
  .partners {
    display: block;
    margin-bottom: 0;
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 25px;
    }
  }
  .partners__item{
    margin-top: 10px;
  }
  .partners-wrapper {
    margin-bottom: 32px;
    flex-wrap: wrap;
  }
  .item-text{
   display: none;
  }
  .item-img {
    margin-left: 0 !important;
    margin-right: 20px !important;
  }
}

@media (max-width: 1100px) {
  .footer {
    border-top: 1px solid white !important;
    padding-top: 0;
  }
}
</style>
