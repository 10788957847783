export function changeUrl(text: string) {
  return text.replaceAll('src=\"/', 'src=\"/api/v1/media/')
}

export function recalculateWiderContainers() {
  const header = document.getElementById("header") as any;
  const style = window.getComputedStyle(header, null);
  style.paddingRight

  const list = document.getElementsByClassName('dynamic-content')

  for (let i = 0; i < list.length; i++) {
    let htmlNode = list[i] as any;

    const onePadding = Number(style.paddingRight.substr(0, style.paddingRight.length - 2));

    const htmlWidth = document.getElementsByTagName("html")[0].clientWidth

    if (htmlWidth > 1600) {
      htmlNode.style.width = ((1420 - 220) / 12 * 8 + onePadding + 140 + (htmlWidth - 1600) / 2) + 'px'
    } else if (htmlWidth > 1100) {
      htmlNode.style.width = (((header.clientWidth - 2 * onePadding)) / 12 * 8 + onePadding) + 'px'
    } else {
      htmlNode.style.width = (header.clientWidth - onePadding) + 'px'
    }
  }
}

export function MetaTitleDescriptionKeywordsChange(obj: any) {
  let mainTitle = "Фонд СВОЁ";
  let mainDescription = "Фонд СВОЁ – площадка для объединения всех игроков продовольственного рынка для развития диалога между производителями продуктов питания и покупателями";
  let mainKeywords = "Своё, Агропродовольственный фонд СВОЁ, Андрей Даниленко, своя еда, агротуризм, фермерская продукция, минсельхоз РФ, агрохолдинг, производство еды, еда в России, фермы, cельское хозяйство";

  let title: any = document.querySelector("meta[name='title']");
  let description: any = window.document.querySelector("meta[name='description']");
  let keywords: any = window.document.querySelector("meta[name='keywords']");

  title.content = obj.title || mainTitle;
  description.content = obj.description || mainDescription;
  keywords.content = obj.keyword || mainKeywords;
}

