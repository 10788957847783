<template>
  <v-app class="background">
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>
