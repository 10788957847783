export default {
  projects: 'Проекты',
  media: 'Медиа',
  aboutFund: 'О фонде',
  news: 'Новости',
  allNews: 'Все новости',
  contacts: 'Контакты',
  competence: 'Наши компетенции',
  fundProjects: 'Проекты фонда',
  allProjects: 'Все проекты',
  mediaProjects: 'Медиапроекты',
  videos: 'Видео',
  allVideos: 'Все видео',
  soviet: 'Попечительский совет',
  team: 'Команда фонда',
  newsAndAd1: 'Новости',
  newsAndAd2: ' и обновления',
  mainOffice: 'Главный офис',
  writeUsBlock: 'Вы можете написать нам, обычно мы отвечаем не дольше одного дня',
  writeUsTitle: 'Вы можете написать\u00A0нам',
  writeUsDesc: 'Обычно мы отвечаем не\u00A0дольше одного дня',
  writeUs: 'Написать нам',
  partners: 'Наши партнеры',
  ministry: 'Министерство \nсельского хозяйства РФ',
  comeback: 'Вернуться назад',
  welcomeDesc: 'агропродовольственный фонд',
  competence1: 'Создание позитивного образа российского продовольствия',
  competence2: 'Развитие фермерства и популяризация сельского образа жизни',
  competence3: 'Исполнение стратегических задач МСХ и РСХБ',
  competence4: 'Изучение потребительских предпочтений',
  competence5: 'Продвижение продуктовой категории или отрасли',
  competence6: 'Взаимодействие с гос.органами',
  mainAboutFundVideo: 'Короткое видео о целях \nи задачах фонда',
  gearCycle: 'Цикл передач о сельском хозяйстве и фермерстве России, о людях',
  ConstituentDocuments: 'Учредительные документы',
  FoundationCharter: 'Устав фонда',
  channel: 'канал',
  channelSvoe: 'Своё с Андреем Даниленко',
  readMore: 'Подробнее...',
  close: 'Закрыть',
  name: 'Имя',
  phoneNumber: 'Номер телефона',
  yourMessage: 'Ваше сообщение',
  send: 'Отправить',
  feedbackMessage: 'Сообщение отправлено',
  errorCaptchaMessage: 'Пожалуйста, заполните капчу',
  ourUpgrades: 'Обновления',
  requiredField: 'Обязательное поле',
  requiredFieldPhone: 'Поле должно быть не менее 10 символов',
  agreementPart1: 'Нажимая кнопку “Отправить” вы соглашаетесь на ',
  agreementPart2: 'обработку персональных данных',
  mapLang: 'ru_RU',
  share: 'Поделиться',
  copyLink: 'Скопировать ссылку',
  showMore: 'Показать еще',
  policy: 'Политика обработки данных',
  copied: 'Ссылка скопирована'
}
