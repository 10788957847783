import { Projects, ProjectTypes } from '@/services/interfaces/interfaces';

export default {
  setName: (state: any, value: string) => {
    state.name = value
  },
  setPhone: (state: any, value: string) => {
    state.phone = value
  },
  setMessage: (state: any, value: string) => {
    state.message = value
  },
};
