import {AllPosts, Category, Post, PostTypes} from '@/services/interfaces/interfaces';

export default {
  category: [] as Category|unknown,
  posts: {
    count: 0,
    next: '',
    previous: '',
    results:[],
  } as AllPosts|unknown,
  upgrades: [] as AllPosts|unknown,
  mainPosts: [] as Post[]|unknown,
  tag: '' as AllPosts | unknown,
  postType: {} as PostTypes | unknown,
  elemID: '' as AllPosts | unknown
};
