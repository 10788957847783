import api from '@/services/api/api';
import { MetaTitleDescriptionKeywordsChange } from "@/helpers/commonHelpers";

export default {

  async getPostsTypes({ commit, rootState }: any) {
    try {

      let res = await api.getPostsTypes().then(res => res)

      commit('setCategories', res)

      return res
    } catch (error) {
      return error
    }
  },
  //
  // async getUpgrades({ commit, rootState }: any) {
  //   try {
  //     const res = await api.getUpgrades(rootState.lang)
  //     commit('setUpgrades', res.data.results)
  //     return res
  //   } catch (error) {
  //     return error
  //   }
  // },
};
