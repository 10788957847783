import { Projects, ProjectTypes } from '@/services/interfaces/interfaces';

export default {
  setName: (state: any, value: string) => {
    state.name = value
  },
  setCompany: (state: any, value: string) => {
    state.company = value
  },
  setAddress: (state: any, value: string) => {
    state.addressOfFarm = value
  },
  setProductFarm: (state: any, value: string) => {
    state.productOfFarm = value
  },
  setPhone: (state: any, value: string) => {
    state.phone = value
  },
  setLinkVideo: (state: any, value: string) => {
    state.linkToVideo = value
  },
  setLinkSN: (state: any, value: string) => {
    state.linkToSN = value
  },
  setAboutFarm: (state: any, value: string) => {
    state.farmMessage = value
  },
  setYouMessage: (state: any, value: string) => {
    state.yourMessageOne = value
  },
  setAboutYouMessage: (state: any, value: string) => {
    state.yourMessage = value
  },
  setVectorMessage: (state: any, value: string) => {
    state.yourMessageTwo = value
  },

  // setMessage: (state: any, value: string) => {
  //   state.message = value
  // },
};
