export default {
  setName({ commit, rootState }: any, name: string) {
    commit('setName', name)
  },
  setCompany({ commit, rootState }: any, name: string) {
    commit('setCompany', name)
  },
  setAddress({ commit, rootState }: any, name: string) {
    commit('setAddress', name)
  },
  setProductFarm({ commit, rootState }: any, name: string) {
    commit('setProductFarm', name)
  },
  setLinkVideo({ commit, rootState }: any, name: string) {
    commit('setLinkVideo', name)
  },
  setPhone({ commit, rootState }: any, phone: string) {
    commit('setPhone', phone)
  },
  setYouMessage({ commit, rootState }: any, msg: string) {
    commit('setYouMessage', msg)
  },
  setAboutFarm({ commit, rootState }: any, name: string) {
    commit('setAboutFarm', name)
  },
  setAboutYouMessage({ commit, rootState }: any, name: string) {
    commit('setAboutYouMessage', name)
  },
  setVectorMessage({ commit, rootState }: any, name: string) {
    commit('setVectorMessage', name)
  },
  setLinkSN({ commit, rootState }: any, name: string) {
    commit('setLinkSN', name)
  },
  // setMessage({ commit, rootState }: any, message: string) {
  //   commit('setMessage', message)
  // }
}
