import {AllPosts, Category, Post, PostTypes, ProjectTypes} from '@/services/interfaces/interfaces';

export default {
  setPost: (state: any, value: AllPosts) => {
    state.posts.count = value.count
    state.posts.next = value.next
    state.posts.previous = value.previous
    state.posts.results = state.posts.results.concat(value.results)
  },

  setMainPosts: (state: any, value: Post[]) => {
    state.mainPosts = value
  },

  setCategories(state: any, value: Category[]) {
    state.category = value
  },

  setUpgrades: (state: any, value: AllPosts) => {
    state.upgrades = value
  },

  setPostTypes: (state: any, value: PostTypes) => {
    state.postType = value
  },

  resetPosts: (state: any) => {
    state.posts = {
      count: 0,
      next: '',
      previous: '',
      results: []
    }
  },
  setTag: (state: any, value: AllPosts) => {
    state.tag = value
  },
};
