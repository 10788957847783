import axios from 'axios'

/**
 * Сконфигурированный axios клиент
 * */

const axiosClient = axios.create({
  timeout: 100000,
  baseURL: `${process.env.VUE_APP_BASE_API_URL}v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
})

export default axiosClient
