export default {
  setName({ commit, rootState }: any, name: string) {
    commit('setName', name)
  },
  setPhone({ commit, rootState }: any, phone: string) {
    commit('setPhone', phone)
  },
  setMessage({ commit, rootState }: any, message: string) {
    commit('setMessage', message)
  }
}
