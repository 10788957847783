import {Project, Projects, ProjectTypes} from '@/services/interfaces/interfaces';

export default {
  projects: {
    count: 0,
    next: '',
    previous: '',
    results: []
  } as Projects|unknown,
  nextPage: '' as string | null,
  mainProjects: [] as Project[]|unknown,
  projectTypes: {} as ProjectTypes,
};
