import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper'

import VueI18n from 'vue-i18n'
import messages from '@/locales/index'
// @ts-ignore
import VueTheMask from 'vue-the-mask'
import App from './App.vue';
import router from './router';
import store from './store';
// @ts-ignore
import YmapPlugin from 'vue-yandex-maps'
// @ts-ignore
import vuetify from './plugins/vuetify'
import './assets/styles/index.scss'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/swiper.css'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'
//import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
Vue.use(VueTheMask)
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper)
Vue.use(VueI18n)
Vue.use(VueSocialSharing)
Vue.use(VueClipboard)
const i18n = new VueI18n({
  locale: 'ru',
  messages,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
