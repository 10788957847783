import { News, OneNews } from '@/services/interfaces/interfaces';

export default {
  setNews: (state: any, value: News) => {
    state.news.count = value.count
    state.news.next = value.next
    state.news.previous = value.previous
    state.news.results = state.news.results.concat(value.results)
  },

  setMainNews: (state: any, value: OneNews[]) => {
    state.mainNews = value
  },

  setUpgrades: (state: any, value: News) => {
    state.upgrades = value
  },

  resetNews: (state: any) => {
    state.news = {
      count: 0,
      next: '',
      previous: '',
      results: []
    }
  },
  setTag: (state: any, value: News) => {
    state.tag = value
  },
};
