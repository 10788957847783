import api from '@/services/api/api';
import { MetaTitleDescriptionKeywordsChange } from "@/helpers/commonHelpers";

export default {
  async getPosts({ commit, rootState }: any, payload: any) {
    try {
      const params: any = { lang: rootState.lang }
      if (payload.page) params.page = payload.page
      if (payload.pageSize) params.page_size = payload.pageSize
      if (payload.tag) params.tag = payload.tag

      const res = await api.getPosts(params)
      if (payload.pageSize === 6) {
        commit('setMainPosts', res.data.results)
      } else {
        commit('setPost', res.data)
      }
      return res
    } catch (error) {
      return error
    }
  },

  async sortPostType({ commit, rootState }: any, payload: any) {
    try {
      const idT: any = {idF: payload.elemID}
      const params: any = { lang: rootState.lang }
      // params.id = idType.id
      if (payload.page) params.page = payload.page
      if (payload.pageSize) params.page_size = payload.pageSize
      if (payload.tag) params.tag = payload.tag

      // const res = await api.sortPostType('5')
      const res = await api.sortPostType(params, idT.idF)

      commit('setPost', res.data)
      return res
    } catch (error) {
      return error
    }
  },

  async getPostsMap({ commit, rootState }: any, payload: any) {
    try {
      const params: any = { lang: rootState.lang }
      if (payload.page) params.page = payload.page
      if (payload.pageSize) params.page_size = payload.pageSize
      if (payload.tag) params.tag = payload.tag

      const res = await api.getPosts(params)
      if (payload.pageSize === 6) {
        commit('setMainPosts', res.data.results)
      } else {
        commit('setPost', res.data)
      }
      return res
    } catch (error) {
      return error
    }
  },

  async getPostsTypes({ commit }: any) {
    try {

      let res = await api.getPostsTypes().then(res => res.data.results)

      commit('setCategories', res.data.results)

      return res
    } catch (error) {
      return error
    }
  },

  async getPost({ rootState }: any, id: string) {
    try {
      let res = await api.getPost(id, rootState.lang).then(res => res.data.results)

      MetaTitleDescriptionKeywordsChange({
        title: res.seo_title,
        description: res.seo_description,
        keyword: res.seo_keyword
      });

      return res
    } catch (error) {
      return error
    }
  },
  //
  // async getUpgrades({ commit, rootState }: any) {
  //   try {
  //     const res = await api.getUpgrades(rootState.lang)
  //     commit('setUpgrades', res.data.results)
  //     return res
  //   } catch (error) {
  //     return error
  //   }
  // },
};
