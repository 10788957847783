export default {
  name: '' ,
  company: '',
  addressOfFarm: '',
  phone: '',
  farmMessage: '',
  yourMessage: '',
  yourMessageOne: '',
  yourMessageTwo: '',
  linkToVideo: '',
  linkToSN: '',
  // setYouMessage: ''
  productOfFarm: ''
};
