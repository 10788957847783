export default {
  projects: 'Projects',
  media: 'Media',
  aboutFund: 'About the fund',
  news: 'News',
  allNews: 'All news',
  contacts: 'Contacts',
  competence: 'Our expertise',
  fundProjects: 'Fund projects',
  allProjects: 'All projects',
  mediaProjects: 'Media projects',
  videos: 'Videos',
  allVideos: 'All videos',
  soviet: 'Board of Trustees',
  team: "Fund's team",
  newsAndAd1: 'News',
  newsAndAd2: 'Updates',
  mainOffice: 'Main office',
  writeUsBlock: 'You can write to us, we usually answer no longer than one day',
  writeUsTitle: 'Write to\u00A0us',
  writeUsDesc: 'We usually respond no longer than one day.',
  writeUs: 'Write to us',
  partners: 'Our partners',
  ministry: 'Ministry of Agriculture RF',
  comeback: 'Come back',
  welcomeDesc: 'agri-food foundation',
  competence1: 'Creating a positive image of Russian food',
  competence2: 'Development of farming and popularization of rural lifestyle',
  competence3: 'Implementation of the strategic tasks of the Ministry of Agriculture and the RSHB',
  competence4: 'Study of consumer preferences',
  competence5: 'Promotion of a product category or industry',
  competence6: 'Interaction with government agencies',
  mainAboutFundVideo: 'A short video about the goals and objectives of the foundation',
  gearCycle: 'Gear cycle about agriculture and farming in Russia, about people',
  ConstituentDocuments: 'Constituent documents',
  FoundationCharter: 'Foundation charter',
  channel: 'channel',
  channelSvoe: 'SVOE with Andrey Danilenko',
  readMore: 'More details...',
  close: 'Close',
  name: 'Name',
  phoneNumber: 'Phone number',
  yourMessage: 'Your message',
  send: 'Send',
  feedbackMessage: 'Message sent',
  errorCaptchaMessage: 'Please fill out the reCAPTCHA',
  ourUpgrades: 'Updates',
  requiredField: 'Required field',
  requiredFieldPhone: 'The field must be at least 10 characters',
  agreementPart1: 'By clicking the "Send" button, you agree to ',
  agreementPart2: 'the processing of personal data',
  mapLang: 'en_US',
  share: 'Share',
  copyLink: 'Copy link',
  showMore: 'Show more',
  policy: 'Data processing policy',
  copied: 'Link copied'
}
