<template>
  <header
    id="header"
    class="header inline-grid-container"
  >
    <v-img
      :src="logo"
      contain
      class="logo"
      @click="$router.push('/main')"
      alt="Свое"
    />
    <div class="header-nav">
      <ul class="d-none d-md-flex main-menu-list">
        <li
          v-for="el in items"
          :key="el.title"
          class="header-nav__item"
          @click="$router.push(el.url)"
        >
          {{ el.title }}
        </li>
      </ul>
      <div
        class="lang d-none d-md-flex"
        @click="changeLang"
      >
        <p :class="{active: lang === 'ru'}">RU</p>
        <p class="delimiter">/</p>
        <p :class="{active: lang === 'en'}">ENG</p>
      </div>
    </div>

    <ContactsBlock
      is-phone
      class="contacts"
    />

    <v-app-bar-nav-icon
      @click="drawer = true"
      class="d-flex d-md-none burger-button"
    />

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      class="d-md-none drawer"
      width="100%"
      right
    >
      <ul class="header-list">
        <div class="d-flex justify-space-between">
          <v-img
            :src="logo"
            contain
            class="logo"
            @click="$router.push('/main')"
            alt="Свое"
          />
          <v-btn
            icon
            large
            color="black"
            class="header__close"
            @click="drawer=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <li
          v-for="el in items"
          :key="el.title"
        >
          <p
            @click="goToLink(el.url)"
            class="header-list__link"
            :class="{active: el.url === url}"
          >{{ el.title }}</p>
        </li>
        <div
          class="lang"
          @click="changeLang"
        >
          <p :class="{active: lang === 'ru'}">RU</p>
          <p class="delimiter">/</p>
          <p :class="{active: lang === 'en'}">ENG</p>
        </div>

        <ContactsBlock
          is-phone
          class="header-list-contacts"
        />
      </ul>
    </v-navigation-drawer>
  </header>
</template>

<script>
import ContactsBlock from '@/components/ContactsBlock.vue';
import { mapMutations } from 'vuex';
import logo from '@/assets/img/logo.svg'

export default {
  name: 'Header',
  data () {
    return {
      drawer: false,
      scrolled: false,
      lang: 'ru',
      url: null,
      items: [
        { title: this.$t('projects'), url: '/projects' },
        { title: this.$t('aboutFund'), url: '/about_fund' },
        { title: this.$t('media'), url: '/media_projects' },
        { title: this.$t('news'), url: '/news' },
        { title: this.$t('contacts'), url: '/contacts' },
        { title: this.$t('Карта ферм'), url: '/map' },
      ],
      logo,
    }
  },

  methods: {
    ...mapMutations(['setLang']),
    goToLink (url) {
      this.url = url
      this.$router.push(url)
    },
    changeLang () {
      this.lang = this.lang === 'ru' ? 'en' : 'ru'
      this.setLang(this.lang)
      this.$i18n.locale = this.lang
      localStorage.setItem('lang', this.lang)
      window.location.reload();
    },
    handleScroll () {
      this.scrolled = window.scrollY > 0;
    },
    calcHeaderWidth () {
      const width = document.getElementsByClassName("main-container")[0].clientWidth
      let header = document.getElementById('header')
      header.style.width = `${width}px`
      if (width > 1100) {
        header.style.paddingLeft = `${0.05625 * width}px`
        header.style.paddingRight = `${0.05625 * width}px`
      } else if (width > 375) {
        header.style.paddingLeft = `${0.06 * width}px`
        header.style.paddingRight = `${0.06 * width}px`
      } else {
        header.style.paddingLeft = `${0.0666666667 * width}px`
        header.style.paddingRight = `${0.0666666667 * width}px`
      }
    }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  mounted () {
    this.lang = localStorage.getItem('lang') || 'ru'
    this.calcHeaderWidth()
    window.addEventListener("resize", this.calcHeaderWidth)

    document.documentElement.style.setProperty('--more-details', this.lang === 'ru' || this.lang === undefined || this.lang === '' ? '"' + 'Подробнее' + '"' : '"' + 'More details' + '"')
  },

  components: { ContactsBlock },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.main-menu-list li:last-child {
  //display: none;
}

.header {
  position: fixed;
  z-index: 10;
  background-color: white;
  padding-top: 31px;
  padding-bottom: 12px;
}
.logo {
  cursor: pointer;
  max-width: 100px;
  max-height: 32px;
  grid-column: 1 / 4;
}

.header-nav {
  padding: 0;
  display: flex;
  align-items: center;
  grid-column: 4 / 8;

  @media (max-width: 1350px) {
    grid-column: 5 / 9;
  }

  &__item {
    font-size: 14px;
    margin-right: 30px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    &:hover {
      color: $green;
      transition: 0.3s;
    }
  }
}

.contacts {
  grid-column: -1 / -4;
  justify-content: flex-end;
}

.burger-button {
  grid-column-start: -1;
}

.lang {
  align-items: center;
  font-size: 14px;
  .delimiter {
    font-size: 16px;
    padding: 0 5px;
  }
  p {
    font-size: 14px !important;
    cursor: pointer;
    opacity: 0.5;
  }
  p.active {
    color: $green;
    opacity: 1;
    transition: 0.3s;
  }
}

@media screen and (max-width: 1100px) {
  .logo {
    width: 74px;
    max-width: 74px;
  }
  .drawer {
    height: 100vh !important;
    max-width: 304px;
  }

  .header-list {
    display: flex;
    flex-direction: column;
    padding-top: 28px;
    padding-left: 32px;
    padding-right: 25px;

    p {
      font-size: 16px;
    }

    li {
      margin-bottom: 30px;
    }
    &__link {
      font-size: 14px;
      text-transform: none;
      cursor: pointer;
    }
    &__link.active {
      color: $text-blue;
    }
  }

  .header__close {
    margin-bottom: 50px;
  }

  .header-list-contacts {
    position: absolute;
    bottom: 46px;
  }
}

@media (max-width: 1350px) {
  .contacts {
    display: none;
  }
}

@media screen and (max-height: 500px) {
  .header-list {
    li {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 480px) {
  .header{
    //padding: 18px 20px 26px!important;
  }
}
</style>
