import api from '@/services/api/api';

export default {
  async getVideos({ commit, rootState }: any, payload: any) {
    try {
      const params: any = { lang: rootState.lang }
      if (payload.page) params.page = payload.page
      if (payload.pageSize) params.page_size = payload.pageSize
      if (payload.typeId) params.type_id = payload.typeId
      const res = await api.getVideos(params)
      if (payload.pageSize === 6) {
        commit('setMainVideos', res.data.results)
      } else {
        commit('setVideos', res.data)
      }
      return res
    } catch (error) {
      return error
    }
  },
  async getVideoTypes({ commit, rootState }: any) {
    try {
      const res = await api.getVideoTypes(rootState.lang)
      commit('setVideoTypes', res.data.results)
      return res
    } catch (error) {
      return error
    }
  },
};
