import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/main',
    name: 'MainPage',
    component: () => import('../views/mainPage/MainPage.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/projects/Projects.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/projects/project/:id',
    name: 'Project',
    component: () => import('../views/projects/Project.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/media_projects',
    name: 'MediaProjects',
    component: () => import('../views/mediaProjects/MediaProjects.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/about_fund',
    name: 'AboutFund',
    component: () => import('../views/aboutFund/AboutFund.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news/News.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/one-news/:id',
    name: 'OneNews',
    component: () => import('../views/news/OneNews.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/contacts/Contacts.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/personal/Personal.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue'),
    meta: { layout: 'empty' },
  },
  {
    path: '/allposts',
    name: 'allposts',
    component: () => import('../views/post/AllPosts.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import('../views/form/form.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/post/:id',
    name: 'Post',
    component: () => import('../views/post/Post.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/map/',
    name: 'Map',
    component: () => import('../views/newpage/NewPage.vue'),
    meta: { layout: 'main' },
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes
});



export default router;
